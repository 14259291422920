@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');
/* stylelint-disable-line import-notation */

$purple: #ba00ff;

* {
  box-sizing: border-box;
}

html, body {
  padding:0;
  margin:0;
  height: 100%;
}

body, input, button {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
}

#root, .App {
  height: 100%;
}

h1 {
  font-size: 24px;
  display: 600;
  margin: 12px 0;
  padding:0;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  font-weight: normal;
  margin: 10px 0 20px 0;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0;
  padding: 0;
}

h4 {
  font-weight: 200;
  font-size: 16px;
  margin: 24px 0 10px;
  padding:0;
}

p {
  font-size: 16px;
  margin: 8px 0;
  padding: 0;
}
