@import './styles/gui.scss';

* {
  box-sizing: border-box;
}

.App {
  padding: 0;
}


/*
  Styled components does not yet (will in next version)
  support conatiner queries, that's why they are here
*/

.CardsWrapper {
  grid-template-columns: repeat(4, 1fr) !important;

  @container (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @container (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @container (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

